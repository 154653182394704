import React from 'react';
import styled from 'styled-components';
import { Button, Grid, Header, Link, Page, Section, SmolBlock } from 'src/components';
import emails from 'src/constants/emails';
import phoneNumbers from 'src/constants/phone-numbers';
import { useIntercom } from 'src/lib';

// keep in sync with footer.support-hours
const SUPPORT_HOURS = '9am to 6pm ET, Monday through Friday';

const SSmolBlock = styled(SmolBlock)`
  &:last-child {
    max-width: none;
    grid-column: 1 / -1;
  }
`;

export default function Contact() {
  const handleIntercomOpen = useIntercom();

  const contacts = [
    {
      title: 'Phone',
      icon: 'phone',
      body: (
        <>
          <p>
            We are available by phone from {SUPPORT_HOURS} at{' '}
            <Link to={phoneNumbers.DEFAULT}>{phoneNumbers.DEFAULT_FORMATTED}</Link>.
          </p>
          <Button to={phoneNumbers.DEFAULT}>Call us</Button>
        </>
      ),
    },
    {
      title: 'Chat',
      icon: 'annotation',
      body: (
        <>
          <p>
            Our experts are ready to connect! If it’s outside of business hours, simply leave us a
            message and we’ll respond the next day.
          </p>
          <Button onPress={handleIntercomOpen}>Start chat</Button>
        </>
      ),
    },
    {
      title: 'Email',
      icon: 'mail',
      body: (
        <>
          <p>
            Send us a note at <Link to={emails.HELP} />. We aim to respond within one business day.
          </p>
          <p>
            For media inquiries, reach out to <Link to={emails.PRESS} />.
            <br />
            For partnership inquiries, <Link to={emails.PARTNERS} />.
          </p>
          <Button to={emails.HELP}>Email us</Button>
        </>
      ),
    },
  ];

  return (
    <Page
      title="Contact"
      description="Our team of experts is ready to support every step of the way."
      path="contact"
      image="/img/og/default.png"
      color="#F4F3F7"
      darkColor="#262527"
    >
      <Header
        narrow
        title="We’re here to help"
        text={
          <>
            Our team of experts is ready to support every step of the way.
            <br />
            Catch’s business hours are <b>{SUPPORT_HOURS}</b> excluding federal holidays.
          </>
        }
      />
      <Section narrow small>
        <Grid num={2}>
          {contacts.map((item) => (
            <SSmolBlock
              title={item.title}
              icon={`/iconic/${item.icon}.svg`}
              key={item.icon}
              text={item.body}
            />
          ))}
        </Grid>
      </Section>
    </Page>
  );
}
